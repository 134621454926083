@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?1ot50b');
  src: url('fonts/icomoon.eot?1ot50b#iefix') format("embedded-opentype"), url('fonts/icomoon.ttf?1ot50b') format("truetype"), url('fonts/icomoon.woff?1ot50b') format("woff"), url('fonts/icomoon.svg?1ot50b#icomoon') format("svg");
  font-weight: normal;
  font-style: normal;
}


[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-unity:before {
  content: "\e907";
}

.icon-toprated:before {
  content: "\e906";
}

.icon-youtube:before {
  content: "\e905";
}

.icon-mail:before {
  content: "\e901";
}

.icon-indiedb:before {
  content: "\e902";
}

.icon-github:before {
  content: "\e903";
}

.icon-gamejolt:before {
  content: "\e904";
}

.icon-itchio:before {
  content: "\e900";
}

.icon-download:before {
  content: "\e9c7";
}

.icon-steam:before {
  content: "\eaac";
}

.icon-search:before {
  content: "\e986";
}

.icon-link:before {
  content: "\e9cb";
}

.icon-info:before {
  content: "\ea0c";
}

.icon-play:before {
  content: "\ea15";
}

.icon-back:before {
  content: "\ea40";
}

.icon-facebook:before {
  content: "\ea91";
}

.icon-twitter:before {
  content: "\ea96";
}

.icon-video:before {
  content: "\ea9d";
}

.icon-tumblr:before {
  content: "\eaba";
}

.icon-linux:before {
  content: "\eabd";
}

.icon-apple:before {
  content: "\eabe";
}

.icon-mac:before {
  content: "\eabf";
}

.icon-android:before {
  content: "\eac0";
}

.icon-windows:before {
  content: "\eac2";
}

.icon-html5:before {
  content: "\eae4";
}
